import { Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import AccountAdminPageComponent from "./components/Pages/AccountAdminPageComponent";
import CreateAccountPageComponent from "./components/Pages/CreateAccountPageComponent";
import HomePageComponent from "./components/Pages/HomePageComponent";
import LoginPageComponent from "./components/Pages/LoginPageComponent";
import LogistikDisplayPageComponent from "./components/Pages/LogistikDisplayPageComponent";
import ProfilePageComponent from "./components/Pages/ProfilePageComponent";
import SettingsPageComponent from "./components/Pages/SettingsPageComponent";
import TransportDisplayPageComponent from "./components/Pages/TransportDisplayPageComponent";
import WeeklyPlannerPage from "./components/Pages/WeeklyPlannerPage";
import TestingPageComponent from "./components/Pages/TestingPageComponent";
import PersistLogin from "./components/PersistLogin";
import RequireAuth from "./components/RequireAuth";
import { DataContextProvider } from "./context/DataProvider";
import { SettingsContextProvider } from "./context/SettingsProvider";
import { TransportUpdateContextProvider } from "./context/TransportUpdateProvider";
import { UpdateContextProvider } from "./context/UpdateProvider";
function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* <Route element={<RequireAuth allowedRoles={[5051]} />}> */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[2001]} />}>
            <Route
              path="/profile"
              element={
                <SettingsContextProvider>
                  <ProfilePageComponent />
                </SettingsContextProvider>
              }
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[5051, 2234]} />}>
            <Route
              path="/"
              element={
                <DataContextProvider>
                  <UpdateContextProvider>
                    <LogistikDisplayPageComponent
                      header="Logistik Centrum"
                      url="logistics"
                    />
                  </UpdateContextProvider>
                </DataContextProvider>
              }
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[5051]} />}>
            <Route path="/admin" element={<AccountAdminPageComponent />} />
            <Route
              path="/createUser"
              element={<CreateAccountPageComponent />}
            />

            <Route path="/settings" element={<SettingsPageComponent />} />
            <Route path="/weeklyplanner" element={<WeeklyPlannerPage />} />
            <Route
              path="/transport/edit"
              element={
                <DataContextProvider>
                  <HomePageComponent
                    header="Ändra Transport Schema"
                    url="transport"
                  />
                </DataContextProvider>
              }
            />
            <Route
              path="/logistik/edit"
              element={
                <DataContextProvider>
                  <UpdateContextProvider>
                    <HomePageComponent
                      header="Ändra Logistik Schema"
                      url="logistics"
                    />
                  </UpdateContextProvider>
                </DataContextProvider>
              }
            />
          </Route>
          <Route element={<RequireAuth allowedRoles={[5051, 1234]} />}>
            <Route
              path="/transport"
              element={
                <DataContextProvider>
                  <TransportUpdateContextProvider>
                    <TransportDisplayPageComponent
                      header="Transport Schema"
                      url="transport"
                    />
                  </TransportUpdateContextProvider>
                </DataContextProvider>
              }
            />
            <Route
              path="/transport/test"
              element={
                <DataContextProvider>
                  <TransportUpdateContextProvider>
                    <TestingPageComponent />
                  </TransportUpdateContextProvider>
                </DataContextProvider>
              }
            ></Route>
          </Route>
        </Route>
        <Route path="/login" element={<LoginPageComponent />} />
      </Route>
    </Routes>
  );
}
export default App;
